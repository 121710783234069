<template>
  <b-modal
    id="modal-survey-form"
    title="Thêm khảo sát"
    size="md"
    centered
    hide-footer
    @hide="resetModal"
    @show="onShow"
  >
    <b-overlay :show="loading">
      <form @submit.prevent="handleValidate">
        <!-- Buổi coach -->
        <basic-select
          v-validate="{
            required: true,
          }"
          :value.sync="form.survey"
          :options="surveyList"
          :disabled="isEdit"
          :state="validateState('survey')"
          :invalidFeedback="errors.first('survey')"
          name="survey"
          required
          data-vv-as="Chọn khảo sát"
          label="Chọn khảo sát"
          placeholder="--- Chọn khảo sát ---"
          changeValueByObject
        />

        <!-- Ngày diễn ra -->
        <b-form-group
          :id="`activeDate-group`"
          label="Chọn ngày diễn ra hoạt động"
          :invalid-feedback="errors.first('activeDate')"
          :state="validateState('activeDate')"
          class="required"
          label-for="activeDate-ID"
        >
          <date-picker
            placeholder="--- Chọn ngày ---"
            name="activeDate"
            required
            input-class="form-control"
            type="date"
            format="DD/MM/YYYY"
            value-type="format"
            :disabled-date="disabledBeforeToday"
            v-model="form.activeDate"
            data-vv-as="Chọn ngày diễn ra hoạt động"
            v-validate="'required'"
          />
        </b-form-group>

        <!-- Actions -->
        <div class="d-flex justify-content-end align-items-center">
          <b-button class="btn mr-2" type="button" @click="onClickCancelButton">
            Hủy
          </b-button>
          <b-button class="btn btn-success ml-3" type="submit">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Lưu
          </b-button>
        </div>
      </form>
    </b-overlay>
  </b-modal>
</template>

<script>
import { MODAL_TYPE } from '@/core/plugins/constants.js';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'SurveyFormModal',
  components: {
    'date-picker': DatePicker,
  },
  props: {
    groupId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      form: {
        survey: null,
        activeDate: null,
      },

      loading: false,
      surveyList: [],
    };
  },

  computed: {
    isEdit() {
      return this.typeForm === MODAL_TYPE.EDIT;
    },
  },

  methods: {
    onShow() {
      this.getSurveys();
    },

    async getSurveys() {
      try {
        this.loading = true;
        const { meta, error, data } = await this.$api.get(
          '/Survey/GetSurveyForTarget',
        );

        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }

        this.surveyList = data.map((el) => ({ id: el.id, name: el.name }));
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.loading = false;
      }
    },

    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (!result) return;

        this.handleSubmit();
      });
    },

    async handleSubmit() {
      const params = {
        trainingGroupId: this.groupId,
        surveyId: this.form.survey.id,
        appointmentDate: this.convertDateToTimestamp(this.form.activeDate),
      };

      try {
        this.loading = true;
        const { meta, error } = await this.$api.post(
          '/Target/CreateSurveyTarget',
          params,
        );

        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }

        this.showToastrMessage('Thêm khảo sát thành công', 'success');
        this.onClickCancelButton();
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.loading = false;
      }
    },

    showToastrMessage(message, type) {
      if (type === 'error') {
        return this.$toastr.e({
          title: 'Lỗi',
          msg: message,
        });
      }

      return this.$toastr.s({
        title: 'Thành công',
        msg: message,
      });
    },

    resetModal() {
      this.form = {
        survey: null,
        activeDate: null,
      };
      this.$emit('reset');
    },

    onClickCancelButton() {
      this.$bvModal.hide('modal-survey-form');
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
